import { WorkoutPieceSpecification, WorkoutGoals, WorkoutPieceStatisticsResponse } from "../../services/rowhero/RowHeroServiceApi";
import { useState } from "react";
import { AthletePieceSplitTableViewModel } from "./viewModels/AthletePieceSplitTableViewModel";
import { Observer } from "mobx-react";
import { observer, useObservable } from "mobx-react-lite";
import React from "react";

export interface AthletePieceSplitTableProps {
  pieceSpec: WorkoutPieceSpecification
  statistics?: WorkoutPieceStatisticsResponse
}

export const AthletePieceSplitTable = observer((props: AthletePieceSplitTableProps) => {
  if (!props.statistics) {
    return null;
  }

  const viewModel = useObservable(new AthletePieceSplitTableViewModel(
    props.pieceSpec,
    props.statistics));

  if (!viewModel.show) {
    return null;
  }

  const options = viewModel.lengthOptions.map(o => (
    <option key={o[0]} value={o[0]}>{o[1]}</option>
  ));

  const splits = viewModel.splits.map(s => 
    (
      <tr key={s.goal}>
        <td>{s.goal}</td>
        <td>{s.score}</td>
        <td style={{ backgroundColor: s.splitColor }}>{s.split}</td>
        <td>{s.rate}</td>
      </tr>
    )
  );

  return (
    <div>
      <div className="split-table-header">
        <span>Interval Length</span>
        <select className="clickable" onChange={viewModel.changeLength} value={viewModel.length}>
          {options}
        </select>
      </div>
      <table className="split-table">
        <thead>
          <tr>
            <td>{viewModel.goalStatTitle}</td>
            <td>{viewModel.scoreStatTitle}</td>
            <td>SPLIT</td>
            <td>RATE</td>
          </tr>
        </thead>
        <tbody>
          {splits}
        </tbody>
      </table>
    </div>
  )
});