import HttpClient from "./HttpClient";
import { Observable, Observer, from, of, throwError } from "rxjs";
import { switchMap } from "rxjs/operators";
import { ApiError } from "../rowhero/RowHeroServiceApi";

export default class FetchHttpClient implements HttpClient {
  
  getAsync(uri: string, headers?: { [key: string]: string }): Observable<Response> {
    return this._createDefaultPipeline(this._makeRequest(uri, 'GET', undefined, headers));
  } 
  
  patchAsync(uri: string, body: any, headers?: { [key: string]: string }): Observable<Response> {
    return this._createDefaultPipeline(this._makeRequest(uri, 'PATCH', body, headers));
  }

  postAsync(uri: string, body: any, headers?: { [key: string]: string }): Observable<Response> {
    return this._createDefaultPipeline(this._makeRequest(uri, 'POST', body, headers));
  }

  putAsync(uri: string, body: any, headers?: { [key: string]: string }): Observable<Response> {
    return this._createDefaultPipeline(this._makeRequest(uri, 'PUT', body, headers));
  }

  _createDefaultPipeline = (observable: Observable<Response>): Observable<Response> => {
    return observable.pipe(
      switchMap((response: Response) => {
        if (response.status >= 400) {
          return from(response.text())
            .pipe(
              switchMap(text => {
                let error = new ApiError(response.url, response.status, text);
                return throwError(error);
              })
            );
        }

        return of(response);
      })
    );
  }

  private _makeRequest = (uri: string, reqMethod: string, body?: any, headers?: { [key: string]: string }): Observable<Response> => {
    return Observable.create((observer: Observer<Response>) => {
      let requestHeaders = headers || {};

      if (body) {
        requestHeaders["Content-Type"] = "application/json";
      }
      
      const bodyString = body && JSON.stringify(body) || undefined;

      console.log(`REQUEST: ${uri}\n${bodyString}`);
      let promise = fetch(uri,
        {
          method: reqMethod,
          headers: requestHeaders,
          body: body && JSON.stringify(body) || undefined
        });

      promise.then(response => {
        observer.next(response);
        observer.complete();
      })
      .catch(error => {
        observer.error(error);
        observer.complete();
      });
    });
  }
}
