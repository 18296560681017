import HttpClient from '../../http/HttpClient.js';
import config from '../../../config/config.json';
import { mapJson } from "../../http/operators";
import { PieceDataResponse } from "../RowHeroServiceApi";

export default class GetPersonalPieceDataQuery {
  constructor(private workoutId: string, private pieceKey: string) {

  }

  queryAsync(rowHeroHttpClient: HttpClient): Promise<PieceDataResponse> {
    const uri = config.rowhero.baseUri + `/v1/me/workouts/${this.workoutId}/pieces/${this.pieceKey}`;

    return rowHeroHttpClient.getAsync(uri)
      .pipe(
        mapJson<PieceDataResponse>()
      )
      .toPromise();
  }
}