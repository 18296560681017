import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from './providers/Auth0Provider';
import config from './config/config.json';
import { AppServiceProvider } from './providers/AppServiceProvider';

const onRedirectCallback = (appState: any) => {
  window.history.replaceState(
    {},
    document.title,
    (appState && appState.targetUrl) || window.location.pathname
  );
};

const initOptions: Auth0ClientOptions = {
  domain: config.auth.domain,
  client_id: config.auth.clientId,
  redirect_uri: window.location.origin,
  scope: config.auth.scope,
  audience: config.auth.audience
};

const rootElement = (
  <Auth0Provider
    initOptions={initOptions}
    onRedirectCallback={onRedirectCallback}>
    <AppServiceProvider>
      <App />
    </AppServiceProvider>
  </Auth0Provider>
);

ReactDOM.render(rootElement, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
