import React from 'react';
import './App.css';
import { useAuth0 } from './providers/Auth0Provider';
import { AuthRequired } from './components/auth/AuthRequired';
import { BrowserRouter, Route } from 'react-router-dom';
import { HomePage } from './components/pages/home/HomePage';
import { Loading } from './components/common/loading/Loading';
import { AthleteTeamWorkoutListPage } from './components/pages/small/athlete/AthleteTeamWorkoutListPage';
import { AthleteWorkoutPieceListPage } from './components/pages/small/athlete/AthleteWorkoutPieceListPage';
import { AthletePersonalWorkoutListPage } from './components/pages/small/athlete/AthletePersonalWorkoutListPage';
import { AthletePieceGraphPage } from './components/pages/small/athlete/AthletePieceGraphPage';

const App: React.FC = () => {
  const { authService } = useAuth0();
  
  if (!authService || authService.loading) {
    return (
      <Loading />
    );
  }

  return (
    <BrowserRouter>
      <AuthRequired />
      <Route path="/" exact={true} component={HomePage} />
      <Route path="/teams/:teamId" component={AthleteTeamWorkoutListPage} />
      <Route path="/me" component={AthletePersonalWorkoutListPage} />
      <Route path="/workouts/:workoutId" component={AthleteWorkoutPieceListPage} exact={true} />
      <Route path="/workouts/:workoutId/pieces/:pieceKey/graph" component={AthletePieceGraphPage} />
    </BrowserRouter>
  );
}

export default App;
