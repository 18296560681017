import React from 'react';
import './styles.css';
import { Link } from 'react-router-dom';

export interface TeamChoiceProps {
  teamId?: string;
  teamName: string;
}

export const TeamChoice = (props: TeamChoiceProps) => {

  const linkLocation = props.teamId ? `/teams/${props.teamId}` : `/me`;
  return (
    <Link to={linkLocation}>
      <div
        className="team-choice">
        <img
          className="team-choice"
          alt={props.teamName}
          src={`https://rowherostorageprod.blob.core.windows.net/domain-images/teams/${props.teamId || `me`}.jpg`} />
        <span className="team-choice-text">{props.teamName}</span>
      </div>
    </Link>
  );
}