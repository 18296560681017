import React, { PropsWithChildren, Ref } from "react";
import "./styles.css";

export interface CardViewProps  {
  className?: string
  hoverEffectEnabled?: boolean
}


export const CardView = (props: PropsWithChildren<CardViewProps>) => {
  let hoverEffectEnabled = props.hoverEffectEnabled === undefined || props.hoverEffectEnabled === null || props.hoverEffectEnabled;
  const className = (props.className || "") + " " + (hoverEffectEnabled ? "with-hover" : "");

  return (
    <div className={`card-view ${className}`}>
      {props.children}
    </div>
  );
};