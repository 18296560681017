import HttpClient from '../../http/HttpClient.js';
import { mapJson } from "../../http/operators";
import { StrokeRecordWireModel } from "../RowHeroServiceApi";

export default class GetPersonalPieceStrokeDataQuery {
  constructor(private strokeDataUri: string) {

  }

  queryAsync(strokeHttpClient: HttpClient): Promise<StrokeRecordWireModel[]> {
    return strokeHttpClient.getAsync(this.strokeDataUri)
      .pipe(
        mapJson<StrokeRecordWireModel[]>()
      )
      .toPromise();
  }
}