// from https://auth0.com/docs/quickstart/spa/react?download=true
import React, { useState, useEffect, useContext } from "react";
import createAuth0Client from "@auth0/auth0-spa-js";
import { AuthenticationService } from "../services/auth";

type AuthContext = {
  authService?: AuthenticationService
};

const DEFAULT_REDIRECT_CALLBACK = (appState: any) =>
  window.history.replaceState({}, document.title, window.location.pathname);

const defaultContext: AuthContext = {
};

export const Auth0Context = React.createContext(defaultContext);
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  initOptions
} : {
  children: any,
  onRedirectCallback: (appState: any) => void,
  initOptions: Auth0ClientOptions
}) => {
  const [authService, setAuthService] = useState<AuthenticationService>();

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      const localAuthService = new AuthenticationService(auth0FromHook);

      if (window.location.search.includes("code=")) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      let tokenMatch = window.navigator.userAgent.match(/^Bearer (.*)$/i);
      if (tokenMatch) {
        localAuthService.setToken(tokenMatch[1]);
      }

      await localAuthService.updateAuthenticatedState();

      console.log('authenticated? ' + localAuthService.isAuthenticated);

      localAuthService.loading = false;
      setAuthService(localAuthService);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const value: AuthContext = {
    authService: authService
  };

  return (
    <Auth0Context.Provider
      value={value}>
      {children}
    </Auth0Context.Provider>
  );
};