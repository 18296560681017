import React from "react";
import "./styles.css";

export interface CardTitleProps {
  className?: string
  style?: React.CSSProperties
  text: string;
}

export const CardTitle = (props: CardTitleProps) => {
  return (
    <span
      className={`card-title ${props.className || ''}`}
      style={props.style}>{props.text}</span>
  );
};