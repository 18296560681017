import React, { useState } from 'react';
import { WorkoutPieceRankingListResponse } from '../../services/rowhero/RowHeroServiceApi';
import { CardView } from '../common/cards/CardView';
import { CardTitle } from '../common/cards/CardTitle';
import "./styles.css";
import { AthleteWorkoutPieceCardViewModel } from './viewModels/AthleteWorkoutPieceCardViewModel';
import { AthletePieceSplitSparkline } from './AthletePieceSplitSparkline';
import { AthletePieceSplitTable } from './AthletePieceSplitTable';
import { Link } from 'react-router-dom';
import { FeatureToggles } from '../../model/FeatureToggles';

export interface AthleteWorkoutPieceCardProps {
  personalWorkoutId: string;
  piece: WorkoutPieceRankingListResponse
  pieceIndex: number
  pieceCount: number
}

export const AthleteWorkoutPieceCard = (props: AthleteWorkoutPieceCardProps) => {
  const viewModel = new AthleteWorkoutPieceCardViewModel(props.piece, props.pieceIndex, props.pieceCount);

  const rank = viewModel.rank && (
    <span className="small-text">
      {viewModel.rank} of {viewModel.total}
    </span>
  );

  const rankRow = rank && props.piece.teamId && FeatureToggles.showRank(props.piece.teamId) && (
    <div style={statRow}>
      <span className="small-emphasized stat-title">RANK</span>
      {rank}
    </div>
  );

  // cd3232, 228B22
  return (
    <CardView className={"piece-card half-width"} hoverEffectEnabled={false}>
      <div style={summary}>
        <div style={leftStack}>
          <CardTitle
            text={viewModel.title}
            className="card-row-buffer" />
          <AthletePieceSplitSparkline
            minHeight={150}
            pieceSpec={viewModel.spec}
            statistics={viewModel.statistics} />
          <div style={{ display: "flex", alignSelf: "stretch", margin: "0px auto", marginTop: "20px" }}>
            <Link to={`/workouts/${props.personalWorkoutId}/pieces/${props.piece.athletes[0].pieceKey}/graph`} className="clickable">
              Full Screen Graph
            </Link>
          </div>
        </div>
        <div style={rightStack}>
          <div style={statRow}>
            <span className="small-emphasized stat-title">SPLIT</span>
            <span className="small-text align-right">{viewModel.split}</span>
          </div>
          <div style={statRow}>
            <span className="small-emphasized stat-title">{viewModel.scoreType}</span>
            <span className="small-text align-right">{viewModel.scoreValue}</span>
          </div>
          <div style={statRow}>
            <span className="small-emphasized stat-title"><a className="clickable" href="#" onClick={viewModel.renderPotentialHelp}>{viewModel.potentialType}</a></span>
            <span className="small-text align-right">{viewModel.potentialValue}</span>
          </div>
          <div style={statRow}>
            <span className="small-emphasized stat-title">RATE</span>
            <span className="small-text align-right">{viewModel.rate}</span>
          </div>
          <div style={statRow}>
            <span className="small-emphasized stat-title">DRAG</span>
            <span className="small-text align-right">{viewModel.drag}</span>
          </div>
          <div style={statRow}>
            <span className="small-emphasized stat-title"><a className="clickable" href="#" onClick={viewModel.renderDriveLengthHelp}>DRIVE LENGTH</a></span>
            <span className="small-text align-right">{viewModel.length}</span>
          </div>
          {rankRow}
        </div>
      </div>

      <div className="piece-list-break"></div>

      <AthletePieceSplitTable
        pieceSpec={viewModel.spec}
        statistics={viewModel.statistics} />

    </CardView>
  );
};

const summary: React.CSSProperties = {
  display: "flex",
  flex: 1,
  flexDirection: "row"
};

const leftStack: React.CSSProperties = {
  alignItems: "flex-start",
  display: "flex",
  flex: 2,
  flexDirection: "column",
};
const rightStack: React.CSSProperties = {
  alignItems: "flex-end",
  display: "flex",
  flexDirection: "column",
};
const statRow: React.CSSProperties = {
  alignItems: "center",
  flexDirection: "row",
  display: "flex",
  flex: 1,
  marginBottom: "5px"
};