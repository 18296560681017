import { useAuth0 } from "../../providers/Auth0Provider";

export const AuthRequired = () => {
  const { authService } = useAuth0();

  if (!authService) {
    return null;
  }

  if (!authService.isAuthenticated) {
    authService.loginWithRedirect();
  }

  return null;
};