import React, { useEffect, useState } from 'react';
import { useAuth0 } from "../../providers/Auth0Provider";
import { ProfileQuery } from '../../services/rowhero/queries';
import { useAppServices } from '../../providers/AppServiceProvider';
import { TeamResponse } from '../../services/rowhero/RowHeroServiceApi';
import { TeamChoice } from './TeamChoice';
import './styles.css';
import { Loading } from '../common/loading/Loading';
import { TeamViewModel } from './TeamViewModel';

export const TeamChoiceList = () => {
  const { authService } = useAuth0();
  const { rowHeroClient } = useAppServices();

  const [teams, setTeams] = useState<TeamViewModel[]>([]);

  useEffect(() => {
    if (!authService || !rowHeroClient) {
      return;
    }

    new ProfileQuery().queryAsync(rowHeroClient)
      .then(profile => {
        const athleteTeams = profile.teams.athleteOf;
        if (!athleteTeams) {
          return;
        }

        const results = athleteTeams.map(a => new TeamViewModel(a.teamName, a.teamId))
          .concat({ teamName: 'My Personal Workouts' });

        setTeams(results);
      });
  }, [authService, rowHeroClient]);

  if (!teams) {
    return (
      <Loading />
    );
  }

  const teamsUI = teams.map(t => (
    <TeamChoice key={t.teamId} {...t} />
  ));

  return (
    <div className="team-choice-list">
      {teamsUI}
    </div>
  );
}