import { DisplayFormatter } from "../../common";

export class PieceExportPreferencesRequest {
  pieceExportFields: string
  splitLength?: number

  constructor(pieceExportFields?: string, splitLength?: number) {
    this.pieceExportFields = pieceExportFields || "none";
    this.splitLength = splitLength;
  }
}

export interface TeamWorkoutHistoryResponse {
  workouts?: TeamWorkoutResponse[]
}

export interface WorkoutResponse {
  workoutId: string
  specString: string
  startTime: string
  startTimeString?: string
}

export interface TeamWorkoutResponse extends WorkoutResponse {
  teamId: string
  

  averageSplit: TeamMetricResponse
  watts: TeamMetricResponse
  offStrokeScore: TeamMetricResponse
}

export interface TeamMetricResponse {
  average?: number
  boatAverages?: (number | undefined)[]
}

export interface PersonalWorkoutHistoryResponse {
  workouts?: PersonalWorkoutResponse[]
}

export interface PersonalWorkoutResponse extends WorkoutResponse {
  personId: string
  current: boolean
  teamId?: string
  teamWorkoutId?: string

  averageSplit: WorkoutMetricResponse
  watts?: WorkoutMetricResponse
  offStrokeScore?: WorkoutMetricResponse
  slowestOffStroke?: WorkoutMetricResponse
}

export interface WorkoutMetricResponse {
   value: number;
   rank: number;
   total: number;
}

export interface RankingListResponse {
  averageSplit: TeamMetricResponse
  totalTime: TeamMetricResponse
  totalDistance: TeamMetricResponse
  watts: TeamMetricResponse
  offStrokeScore: TeamMetricResponse
}

export interface WorkoutRankingListResponse extends RankingListResponse {
  workoutId: string;
  pieces: WorkoutPieceRankingListResponse[]
  athletes: OverallAthleteRankingResponse[]    
  specString: string;
}

export interface WorkoutSetSpecification {
  pieceSpecification?: WorkoutPieceSpecification
  setSpecification?: WorkoutSetSpecification
  repetitions: number
  setRest: WorkoutRest
}

export interface WorkoutPieceSpecification {
  goal: WorkoutGoal
  rest?: WorkoutGoal
  repetitions: number
}

export interface WorkoutGoal {
  type: string
  value: number
  rateCap?: number
}

export class WorkoutGoals {
  public static time: string = 'seconds';
  public static distance: string = 'meters';
  public static calories: string = 'calories';

  public static toString = (goal: WorkoutGoal): string => {
    let rate = goal.rateCap && ` R${goal.rateCap}` || '';

    switch (goal.type) {
      case WorkoutGoals.time:
        return DisplayFormatter.formatTimeSpec(goal.value) + rate;
      case WorkoutGoals.distance:
        return DisplayFormatter.formatMeters(goal.value, 0) + rate;
      case WorkoutGoals.calories:
        return `${goal.value} cal` + rate;
    }

    throw new Error('Unknown workout goal type ' + goal.type);
  }
}

export interface WorkoutRest {
  type: string
  value: number
}

export interface WorkoutPieceRankingListResponse extends RankingListResponse {
  pieceNumber: number;
  spec: WorkoutPieceSpecification
  specString: string;
  athletes: PieceAthleteRankingResponse[]

  teamId?: string;
  personalWorkoutId?: string;
  teamWorkoutId?: string;
}

export interface OverallAthleteRankingResponse {
  personId: string;
  fullName: string;
  overallGrit?: number;

  pieces: AthletePieceResultResponse[];

  statistics?: WorkoutPieceStatisticsResponse;
  startTime?: Date;

  averageSplit: WorkoutMetricResponse
  watts?: WorkoutMetricResponse
  offStrokeScore?: WorkoutMetricResponse
  slowestOffStroke?: WorkoutMetricResponse
}

export interface PieceAthleteRankingResponse  {
  personId: string;
  fullName: string;

  pieceKey: string

  statistics?: WorkoutPieceStatisticsResponse;
  startTime?: Date;
  
  averageSplit: WorkoutMetricResponse
  watts?: WorkoutMetricResponse
  offStrokeScore?: WorkoutMetricResponse
  slowestOffStroke?: WorkoutMetricResponse

  allTimeRecord?: PieceRecordResponse;
  seasonRecord?: PieceRecordResponse;

  previousPiece?: PreviousPieceResponse;
}

export interface AthletePieceResultResponse  {
  pieceNumber: number;
  spec: WorkoutPieceSpecification;
  specString: string;

  pieceKey: string

  statistics?: WorkoutPieceStatisticsResponse;
  startTime?: Date;
  
  averageSplit?: WorkoutMetricResponse
  watts?: WorkoutMetricResponse
  offStrokeScore?: WorkoutMetricResponse
  slowestOffStroke?: WorkoutMetricResponse

  allTimeRecord?: PieceRecordResponse;
  seasonRecord?: PieceRecordResponse;
}

export interface WorkoutPieceStatisticsResponse {
  pieceKey: string;
  elapsedTimeInSeconds: number;
  distanceInMeters: number;
  averageSplit: number;
  strokeCount?: number;
  strokeRate: number;

  dragFactor?: number;
  strokeLength?: number;

  averagePowerInWatts?: number;

  splits?: SplitSegmentResponse[]

  offStrokeScore?: number;
  offStrokeImpact?: number;
  slowestOffStroke?: number;

  potentialSplit?: number;
  potentialScore?: number;
  offStrokeScoreImpact?: number;
  offStrokeSplitImpact?: number;

  walls?: MentalWallResponse[]
}

export interface MentalWallResponse {
  scoreImpact: number;
  splitImpact: number;
  startLocation: number;
  endLocation: number;
}

export interface SplitSegmentResponse {
  distance: number;
  time: number;
  strokeCount: number;
}

export interface PieceRecordResponse {
  rateCapped: boolean
  nonRateCapped: boolean;
  pieceRecordType: PieceRecordType;
  pieceRecordTypeQualifier?: string;
  previousRateCappedRecord?: PreviousPieceResponse;
  previousNonRateCappedRecord?: PreviousPieceResponse;
}

export interface PreviousPieceResponse {
  meters: number;
  seconds: number;
  split: number;
  watts: number;
  pieceKey: string;
  startTime: string;
}

export type PieceRecordType = 'AllTime' | 'RowingYear';

///////////////
// Team growth interfaces
///////////////

export interface PieceGrowthRankingListResponse {
  specification: WorkoutPieceSpecification;
  specString: string;
  startTime: string;
  endTime: string;

  pieces: PieceGrowthPieceRankingListResponse[];
  athletes: PieceGrowthOverallAthleteRankingListResponse[];

  averageSplitGrowth: TeamMetricResponse;
  wattGrowth: TeamMetricResponse;
  wattPercentageGrowth: TeamMetricResponse;
  offStrokeScoreGrowth: TeamMetricResponse;
  totalTimeGrowth: TeamMetricResponse;
  totalDistanceGrowth: TeamMetricResponse;
}

export interface PieceGrowthPieceRankingListResponse {
  personalWorkoutId?: string; // If null, represents a team piece, not a personal one.
  teamWorkoutId?: string;
  startTime: string;

  averageSplit: TeamMetricResponse;
  totalDistance: TeamMetricResponse;
  totalTime: TeamMetricResponse;
  watts: TeamMetricResponse;
  offStrokeScore: TeamMetricResponse;
}

export interface PieceGrowthOverallAthleteRankingListResponse {
  personId: string
  fullName: string

  pieces: PieceAthleteRankingResponse[]

  averageSplitGrowth?: WorkoutMetricResponse
  wattGrowth?: WorkoutMetricResponse
  wattPercentageGrowth?: WorkoutMetricResponse
  totalTimeGrowth?: WorkoutMetricResponse
  totalDistanceGrowth?: WorkoutMetricResponse
  offStrokeScoreGrowth?: WorkoutMetricResponse

  // Averages for previous pieces
  averageSplit: WorkoutMetricResponse
  watts: WorkoutMetricResponse
  offStrokeScore: WorkoutMetricResponse
  slowestOffStroke: WorkoutMetricResponse
}

export interface PieceDataResponse {
  uri: string;
  pieceSpec: WorkoutPieceSpecification;
  statistics: WorkoutPieceStatisticsResponse
}

export interface StrokeRecordWireModel {
  cet: number // milliseconds
  sc: number
  s: number
  cdr: number
  sr?: number
  p: number
  hr?: number
  df?: number
  w?: number
  sl?: number
  pdf?: number
  adf?: number
  idf?: number
  dt?: number
  rt?: number
  dps?: number
}

export class TeamDeviceHeartbeatsResponse {
  needsAttention!: HeartbeatWarningResponse[];
  connected!: DeviceHeartbeatWireModel[];
  offline!: AthleteWireModel[];

  resolveIcon(personId: string) {
    let icon = this.connected && this.connected.find(p => p.personId === personId) && '✅';
    icon = icon || this.needsAttention && this.needsAttention.find(p => p.heartbeat.personId === personId) && '⚠️';
    icon = icon || this.offline && this.offline.find(p => p.id === personId) && '⚪️';

    return icon;
  }
} 

export interface ProfileDataResponse {
  name: string
  pictureUri?: string
  teams: TeamsResponse
}

export interface TeamsResponse {
  athleteOf?: TeamResponse[]
  coachOf?: TeamResponse[]
}

export interface TeamResponse {
  teamId: string
  teamName: string
  athletes: PersonResponse[]
}

export interface PersonResponse {
  id: string
  name: string
}

export class HeartbeatWarnings {
  public static readonly MultipleSignIn : string = "multiple sign-in";
  public static readonly NoHeartbeat : string = "no heartbeat";
  public static readonly LowBattery : string = "low battery";
}

export interface HeartbeatWarningResponse {
  // multiple sign-in, disconnected, low battery
  reasonCode: string;
  heartbeat: DeviceHeartbeatWireModel;
}

export interface DeviceHeartbeatWireModel {
  deviceVersion: string;
  teamId: string;
  personId: string;
  clientHeartbeatTime: Date;
  batteryPercentage: number;
  connectedToConcept2: boolean;

  activelyRowing: boolean;
  dragFactor?: number;
  distanceRowedInMeters?: number;
  elapsedTimeInSeconds?: number;

  movingAverageSplitInSeconds?: number;

  spec?: WorkoutPieceSpecification
  workoutId?: string

  athleteName: string
  deviceId: string
}

export interface AthleteWireModel {
  id: string;
  name: string;
  sex: string;
}

export class ApiError {
  constructor(public uri: string, public status: number, public serviceText: string, public userMessage?: string) {
  }

  debugMessage(): string {
    return this.status + "/" + this.serviceText;
  }

  toString(): string {
    return this.debugMessage();
  }
}

export class ServiceTime {
  public static format = 'YYYY-MM-DD HH:mm:ss.SSSZ';
}

export interface PieceResultResponse {
  strokeDataBlobUri: string;
}

export class Concept2DeviceInfo {
  model: string = 'Concept2 PM5';
  identifier: string

  hardwareVersion: string
  firmwareVersion: string

  constructor(serialNumber: string, hardwareVersion: string, firmwareVersion: string) {
    this.identifier = serialNumber;
    this.hardwareVersion = hardwareVersion;
    this.firmwareVersion = firmwareVersion;
  }
}