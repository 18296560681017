import HttpClient from './HttpClient';
import { AuthenticationService } from '../auth';
import { Observable, throwError, from } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiError } from '../rowhero/RowHeroServiceApi';
import { standardRetry } from './operators';

export default class RowHeroHttpClient implements HttpClient {
  constructor(public accessToken: string, private httpClient: HttpClient, private authenticationService: AuthenticationService) {

  }

  getAsync(uri: string, headers?: { [key: string]: string; }): Observable<Response> {
    headers = this._initializeHeaders(headers);

    return this._createDefaultPipeline(this.httpClient.getAsync(uri, headers), headers);
  }
  
  patchAsync(uri: string, body: any, headers?: { [key: string]: string; }): Observable<Response> {
    headers = this._initializeHeaders(headers);

    return this._createDefaultPipeline(this.httpClient.patchAsync(uri, body, headers), headers);
  }

  postAsync(uri: string, body: any, headers?: { [key: string]: string; }): Observable<Response> {
    headers = this._initializeHeaders(headers);

    return this._createDefaultPipeline(this.httpClient.postAsync(uri, body, headers), headers);
  }

  putAsync(uri: string, body: any, headers?: { [key: string]: string; }): Observable<Response> {
    headers = this._initializeHeaders(headers);

    return this._createDefaultPipeline(this.httpClient.putAsync(uri, body, headers), headers);
  }

  private _initializeHeaders = (headers?: { [key: string]: string; }): { [key: string]: string; }  => {
    if (!headers) {
      headers = {};
    }

    headers["Authorization"] = "Bearer " + this.accessToken;

    return headers;
  }

  private _createDefaultPipeline = (observable: Observable<Response>, headers: { [key: string]: string; }) => {
    return observable.pipe(
      catchError(this._checkAuthentication(headers)),
      standardRetry()
    )
  }

  private _checkAuthentication = (headers: { [key: string]: string }): (error: ApiError) => Observable<any> => {
    return err => {
      if (err.status === 401) {
        return from(this.authenticationService.loginWithRedirect());
      }

      return throwError(err);
    };
  }
}