import moment from "moment";
import React from "react";

export interface DateProps {
  className?: string;
  style?: React.CSSProperties
  value: string;
}

export const Date = (props: DateProps) => {
  const date = moment(props.value);

  let now = moment();
  let startTimeString = '';
  if (now.diff(date, 'days') > 7) {
    if (now.year() > date.year()) {
      startTimeString = date.format('MMM D, YYYY');
    } else {
      startTimeString = date.format('MMM D');
    }
  } else {
    startTimeString = date.fromNow();
  }

  return (
    <span className={props.className} style={props.style}>
      {startTimeString}
    </span>
  );
}