import React, { useEffect, useState } from 'react';
import { useAppServices } from '../../providers/AppServiceProvider';
import { GetPersonalWorkoutHistoryQuery, WorkoutHistoryParams } from '../../services/rowhero/queries';
import { PersonalWorkoutResponse } from '../../services/rowhero/RowHeroServiceApi';
import { Loading } from '../common/loading/Loading';
import { AthleteWorkoutCard } from './AthleteWorkoutCard';
import InfiniteScroll from 'react-infinite-scroller';
import GetPersonalWorkoutHistoryByTeamQuery from '../../services/rowhero/queries/GetPersonalWorkoutHistoryByTeamQuery';

export interface AthleteWorkoutListProps {
  teamId?: string;
}

export const AthleteWorkoutList = (props: AthleteWorkoutListProps) => {
  const { rowHeroClient } = useAppServices();

  const [workouts, setWorkouts] = useState<PersonalWorkoutResponse[]>();
  const [hasMoreItems, setHasMoreItems] = useState(true);
  
  const loadMore = (page: number) => {
    if (!rowHeroClient) {
      return;
    }

    const params = WorkoutHistoryParams.fromPage(page);

    if (params.from.year < 2018
      || params.from.year === 2018 && params.from.month <= 6) {
      setHasMoreItems(false);
    }

    const query = props.teamId
      ? new GetPersonalWorkoutHistoryByTeamQuery(props.teamId, params)
      : new GetPersonalWorkoutHistoryQuery(params);

    query
      .queryAsync(rowHeroClient)
      .then(value => {
        const result = value.workouts || [];
        const newWorkouts = (workouts || []).concat(result);
        setWorkouts(newWorkouts);
      });
  }
  useEffect(() => {
    if (!rowHeroClient) {
      return;
    }

    loadMore(0);
  }, [rowHeroClient]);

  

  if (workouts === undefined) {
    return <Loading />;
  }

  if (!workouts.length && !hasMoreItems) {
    return (
      <span>No workouts found on this team. :(</span>
    )
  }

  const cards = workouts.map(w => <AthleteWorkoutCard key={w.workoutId} workout={w} />);

  // Not sure why this removes a "duplicate key" error
  const loading = <Loading key={1} />;

  return (
    <InfiniteScroll
      loadMore={loadMore}
      hasMore={hasMoreItems}
      loader={loading}>
      {cards}
    </InfiniteScroll>
  );
};