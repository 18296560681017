import { Observable, OperatorFunction, from, throwError, MonoTypeOperatorFunction, timer, of } from "rxjs";
import { switchMap, retryWhen, catchError } from "rxjs/operators";
import { ApiError } from "../rowhero/RowHeroServiceApi";

export function mapJson<T>() : OperatorFunction<Response | undefined, T> {
  return (observable: Observable<Response | undefined>) => observable.pipe(
    switchMap(response => response && _extractJson<T>(response) || of(undefined))
  );
}

const _extractJson = <T>(response: Response): Observable<any> => {
  if (response.ok) {
    return from(response.json() as Promise<T>);
  }

  return of(undefined);
}

export function standardRetry(): MonoTypeOperatorFunction<Response> {
  return retryWhen(errors => {
    return errors.pipe(
      switchMap((error: ApiError, attempts) => {
        const retryAttempt = attempts + 1;
        const status = error.status;

        if (status < 500 || retryAttempt > 2) {
          if (status !== 404) {
            // TODO: Tracing
            // AppEvents.trace(new ServiceRequestFailedEvent(error.uri, error.status.toString(), error.serviceText));
          }

          return throwError(error);
        }
  
        return timer(1000 * retryAttempt);
      })
    );
  });
}

export function ignore404(): OperatorFunction<Response, Response | undefined> {
  return catchError((err: ApiError) => {
    if (err.status === 404) {
      return of(undefined);
    }

    return throwError(err);
  });
}