import HttpClient from '../../http/HttpClient.js';
import config from '../../../config/config.json';
import { mapJson, ignore404 } from "../../http/operators";
import { WorkoutRankingListResponse } from '../RowHeroServiceApi';

export default class GetPersonalWorkoutResultsQuery {
  constructor(private workoutId: string) {

  }

  queryAsync(httpClient: HttpClient): Promise<WorkoutRankingListResponse | undefined> {
    const uri = config.rowhero.baseUri + `/v1/me/workouts/${this.workoutId}/rankings`; 

    return httpClient.getAsync(uri)
      .pipe(
        ignore404(),
        mapJson<WorkoutRankingListResponse>()
      )
      .toPromise();
  }
}