import { WorkoutPieceRankingListResponse, WorkoutGoals, WorkoutPieceStatisticsResponse, PreviousPieceResponse, WorkoutPieceSpecification } from "../../../services/rowhero/RowHeroServiceApi";
import { DisplayFormatter } from "../../../common";
import moment from "moment";

export class AthleteWorkoutPieceCardViewModel {
  spec: WorkoutPieceSpecification;
  pieceKey: string;
  statistics?: WorkoutPieceStatisticsResponse

  title: string;
  split: string = "--";
  scoreType: string;
  scoreValue: string = "--";
  potentialType: string;
  potentialValue: string = "--";

  rank?: string;
  total?: string;

  rate: string = "--";
  length: string = "--";
  drag: string = "--";
  offStrokeScore: string = "--";
  offStrokeImpact: string = "--";
  slowestOffStroke: string = "--";

  previousPiece?: AthleteWorkoutPieceComparison;
  allTimeRecord?: AthleteWorkoutPieceComparison;
  seasonRecord?: AthleteWorkoutPieceComparison;

  constructor(private piece: WorkoutPieceRankingListResponse, pieceIndex: number, pieceCount: number) {
    this.spec = piece.spec;

    this.scoreType = this.spec.goal.type === WorkoutGoals.distance ? "TIME" : "DISTANCE";
    this.potentialType = this.spec.goal.type === WorkoutGoals.distance && this.spec.goal.value === 2000 ? "POTENTIAL TIME" : "POTENTIAL SPLIT";

    let prefix = "";
    if (pieceCount > 1) {
      prefix = `Piece ${pieceIndex + 1}. `;
    }

    this.title = prefix + piece.specString;

    const hasRateCap = !!piece.spec.goal.rateCap;
    const athlete = piece.athletes[0];
    this.pieceKey = athlete.pieceKey;

    const { 
      allTimeRecord,
      averageSplit,
      previousPiece,
      seasonRecord,
      statistics
    } = athlete;

    if (!statistics) {
      // Don't think we should get here...
      return;
    }

    this.statistics = statistics;
    this.split = DisplayFormatter.formatTime(averageSplit.value, 1);
    this.scoreValue = this.scoreType === "TIME"
      ? DisplayFormatter.formatTime(statistics.elapsedTimeInSeconds, 2)
      : DisplayFormatter.formatNumber(statistics.distanceInMeters, 1);

    if (averageSplit.total > 1) {
      this.rank = DisplayFormatter.formatOrdinal(averageSplit.rank);
      this.total = DisplayFormatter.formatOrdinal(averageSplit.total);
    }

    this.rate = DisplayFormatter.formatNumber(statistics.strokeRate, 1, true);
    this.length = statistics.strokeLength && DisplayFormatter.formatNumber(statistics.strokeLength, 0) || "--";
    this.drag = statistics.dragFactor && DisplayFormatter.formatNumber(statistics.dragFactor, 0) || "--";

    if (statistics.potentialSplit) {
      this.potentialValue = this.potentialType === "POTENTIAL TIME"
        ? DisplayFormatter.formatTime(statistics.potentialScore!, 1)
        : DisplayFormatter.formatTime(statistics.potentialSplit, 1);
    }

    let alreadyHighlighted = false;
    if (previousPiece) {
      this.previousPiece = new AthleteWorkoutPieceComparison('Last', statistics, previousPiece, false);
    }

    if (allTimeRecord) {
      const record = hasRateCap ? allTimeRecord.previousRateCappedRecord : allTimeRecord.previousNonRateCappedRecord;
      if (record) {
        this.allTimeRecord = new AthleteWorkoutPieceComparison('PR', statistics, record, !alreadyHighlighted);
        alreadyHighlighted = this.allTimeRecord.highlight;
      }
    }

    if (seasonRecord) {
      const record = hasRateCap ? seasonRecord.previousRateCappedRecord : seasonRecord.previousNonRateCappedRecord;
      if (record) {
        this.seasonRecord = new AthleteWorkoutPieceComparison('Season PR', statistics, record, alreadyHighlighted);
      }
    }
  }

  renderPotentialHelp = () => {
    alert(`Potential Time/Split estimates how fast you could've gone if you paced yourself consistently and avoided off-strokes and mental walls.`);
  }

  renderDriveLengthHelp = () => {
    alert('Drive Length measures how far you pulled the handle every stroke, on average. It is measured in centimeters.');
  }

  createComparisonRows = (): AthleteWorkoutPieceComparison[] => {
    return [];
    // let array = [];
    // if (this.previousPiece) {
    //   if (this.seasonRecord && this.seasonRecord.pieceKey !== this.previousPiece.pieceKey) {
    //     if (this.allTimeRecord && this.allTimeRecord.pieceKey !== this.previousPiece.pieceKey) {
    //       array.push(this.previousPiece);
    //     }
    //   }
    // }

    // if (this.seasonRecord) {
    //   if (this.allTimeRecord && this.allTimeRecord.pieceKey !== this.seasonRecord.pieceKey) {
    //     array.push(this.seasonRecord);
    //   }
    // }

    // if (this.allTimeRecord) {
    //   array.push(this.allTimeRecord);
    // }

    // return array;
  }
}

export type ArrowDirection = "up" | "down" | "neutral";
export type ArrowPolarity = "positive" | "negative" | "neutral";

export class AthleteWorkoutPieceComparison {
  highlight: boolean;
  date: string;
  arrowDirection: ArrowDirection;
  arrowPolarity: ArrowPolarity;
  difference: string;
  pieceKey: string;

  constructor(public title: string, statistics: WorkoutPieceStatisticsResponse, record: PreviousPieceResponse, highlightIfBetter: boolean) {
    this.date = moment(record.startTime).format('MMM D');
    
    const difference = statistics.averageSplit - record.split;

    this.arrowDirection = difference > 0 ? "up" : difference < 0 ? "down" : "neutral";
    this.arrowPolarity = difference > 0 ? "negative" : difference < 0 ? "positive" : "neutral";
    this.difference = DisplayFormatter.formatTimeChange(difference, 1);
    this.highlight = highlightIfBetter && this.arrowPolarity === "positive";
    this.pieceKey = record.pieceKey;
  }
}