import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AthleteWorkoutPieceList } from '../../../workouts/AthleteWorkoutPieceList';

export const AthleteWorkoutPieceListPage = (props: RouteComponentProps<any>) => {
  const { params } = props.match;

  return (
    <AthleteWorkoutPieceList
      workoutId={params.workoutId} />
  );
}