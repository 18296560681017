import React from "react";
import { PersonalWorkoutResponse } from "../../services/rowhero/RowHeroServiceApi";
import { CardView } from "../common/cards/CardView";
import { CardTitle } from "../common/cards/CardTitle";
import { Date } from "../common/text/Date";
import { DisplayFormatter } from "../../common";
import { Rank } from "../common/text/Rank";
import { Link } from "react-router-dom";
import { FeatureToggles } from "../../model/FeatureToggles";

export interface AthleteWorkoutCardProps {
  workout: PersonalWorkoutResponse
}

export const AthleteWorkoutCard = (props: AthleteWorkoutCardProps) => {
  const rank = props.workout.teamId && FeatureToggles.showRank(props.workout.teamId) && (
    <Rank
      value={props.workout.averageSplit}
      className="small-text" />
  );
  return (
    <Link to={`/workouts/${props.workout.workoutId}`}>
      <CardView>
        <div style={row}>
          <div style={leftStack}>
            <CardTitle
              text={props.workout.specString}
              className="card-row-buffer" />
            <Date
              value={props.workout.startTime}
              className="small-text" />
          </div>
          <div style={rightStack}>
            <span className="card-row-buffer">{DisplayFormatter.formatTime(props.workout.averageSplit.value, 1)}</span>
            {rank}
          </div>
        </div>
      </CardView>
    </Link>
  );
}

const leftStack: React.CSSProperties = {
  alignItems: "flex-start",
  display: "flex",
  flexDirection: "column",
  flex: 1
};

const rightStack: React.CSSProperties = {
  alignItems: "flex-end",
  display: "flex",
  flex: 1,
  flexDirection: "column",
};

const row: React.CSSProperties = {
  display: "flex",
  flexDirection: "row"
}