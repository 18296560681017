import HttpClient from '../../http/HttpClient.js';
import config from '../../../config/config.json';
import { mapJson } from "../../http/operators";
import { PersonalWorkoutHistoryResponse } from "../RowHeroServiceApi";
import WorkoutHistoryParams from "./WorkoutHistoryParams";

export default class GetPersonalWorkoutHistoryQuery {
  constructor(private params: WorkoutHistoryParams) {
    
  }

  queryAsync(httpClient: HttpClient): Promise<PersonalWorkoutHistoryResponse> {
    const uri = config.rowhero.baseUri + `/v2/me/workouts/history/${this.params.from.toString()}-${this.params.to.toString()}`;

    return httpClient.getAsync(uri)
      .pipe(
        mapJson<PersonalWorkoutHistoryResponse>()
      )
      .toPromise();
  }
}