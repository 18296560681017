import { WorkoutPieceSpecification, WorkoutPieceStatisticsResponse, WorkoutGoals } from "../../../services/rowhero/RowHeroServiceApi";
import { observable, action, decorate } from "mobx";
import { DisplayFormatter } from "../../../common";
import { ChangeEvent } from "react";
import ColorScale from "../../../common/ColorScale";

const Pyramid4321 = -1;

export class AthletePieceSplitTableViewModel {
  @observable
  public show: boolean = false;

  @observable
  public length: number;
  
  @observable
  public lengthOptions: [number, string][];
  
  @observable
  public goalStatTitle: string;
  
  @observable
  public scoreStatTitle: string;

  @observable
  public splits: SplitViewModel[]

  constructor(private pieceSpec: WorkoutPieceSpecification, private statistics: WorkoutPieceStatisticsResponse) {
    this.length = this._initialLength();
    this.lengthOptions = this._setupLengths();
    this.show = !!this.statistics.splits && !!this.lengthOptions.length;

    if (this.lengthOptions.length && !this.lengthOptions.some(o => o[0] === this.length)) {
      this.length = this.lengthOptions[0][0];
    }

    this.goalStatTitle = pieceSpec.goal.type === WorkoutGoals.distance ? "DISTANCE" : "TIME";
    this.scoreStatTitle = pieceSpec.goal.type === WorkoutGoals.distance ? "TIME" : "DISTANCE";

    this.splits = this._createSplits();
  }

  @action
  public changeLength = (event: ChangeEvent<HTMLSelectElement>): void => {
    this.length = parseInt(event.target.value);
    this.splits = this._createSplits();
  }

  private _createSplits = (): SplitViewModel[] => {
    if (!this.statistics.splits) {
      return [];
    }

    const isDistance = this.pieceSpec.goal.type === WorkoutGoals.distance;
    return this.statistics.splits
      .filter((s, index, array) => {
        const isLast = index === array.length - 1;

        if (this.length === -1) {
          return s.time === 240
            || s.time === 420
            || s.time === 540
            || s.time === 600
            || s.time === 720
            || s.time === 900
            || s.time === 1140;
        }
        if (isDistance) {
          return s.distance % this.length === 0 || isLast;
        }

        return s.time % this.length === 0 || isLast;
      })
      .map((s, index, array) => {
        let last = index === 0 ? null : array[index - 1];

        let { distance, time, strokeCount } = s;

        if (last) {
          if (isDistance) {
            time -= last.time;
          }
          else {
            distance -= last.distance;
          }
          
          strokeCount -= last.strokeCount;
        }

        const lastDistance = last ? last.distance : 0;
        const lastTime = last ? last.time : 0;

        const rateTime = isDistance ? time : time - lastTime;
        const rate = strokeCount / (rateTime / 60.0);
        const split = isDistance ? time / (distance - lastDistance) * 500.0 : (time - lastTime) / distance * 500.0;
        const meanDeviation = this.statistics.averageSplit - split;
        const color = ColorScale.createColor(meanDeviation, 5, -1, -5);

        const vm = new SplitViewModel(this.pieceSpec, distance, time, split, rate);
        vm.splitColor = color;

        return vm;
      });
  };

  private _setupLengths = (): [number, string][] => {
    let retVal: [number, string][] | undefined = undefined;
    if (this.pieceSpec.goal.type === WorkoutGoals.distance) {
      retVal = [
        [ 100, "100" ],
        [ 200, "200" ],
        [ 250, "250" ],
        [ 500, "500" ],
        [ 1000, "1000" ],
        [ 2000, "2000" ],
        [ 5000, "5000" ],
      ];
    } else {
      retVal = [
        [ 30, ":30" ],
        [ 60, "1:00" ],
        [ 120, "2:00" ],
        [ 180, "3:00" ],
        [ 240, "4:00" ],
        [ 300, "5:00" ],
        [ 600, "10:00" ],
        [ 1200, "20:00" ]
      ];

      if (this.pieceSpec.goal.value === 19 * 60) {
        retVal.push([ Pyramid4321, "4'-3'-2'-1'"]);
      }
    }


    retVal = retVal.filter(v => v[0] <= this.pieceSpec.goal.value / 2);

    return retVal;
  }

  private _initialLength = (): number => {
    const { type, value } = this.pieceSpec.goal;

    if (type === WorkoutGoals.time) {
      if (value === 9 * 60) {
        return 3 * 60;
      }

      if (value === 19 * 60) {
        return Pyramid4321;
      }

      return value / 4;
    }

    if (type === WorkoutGoals.distance) {
      if (value === 2000) {
        return 500;
      }

      if (value === 6000) {
        return 1000;
      }

      if (value % 500 === 0) {
        return 500;
      }

      return this.statistics.distanceInMeters / 4;
    }

    return this.statistics.elapsedTimeInSeconds / 4;
  }
}

export class SplitViewModel {
  public distance: string;
  public time: string;
  public split: string;
  public splitValue: number;
  public rate: string;

  public goal: string;
  public score: string;

  public splitColor: string = ColorScale.NeutralColor;

  constructor(pieceSpec: WorkoutPieceSpecification, distance: number, time: number, split: number, rate: number) {
    this.distance = DisplayFormatter.formatNumber(distance, 0);
    this.time = DisplayFormatter.formatTime(time, 1);
    this.splitValue = split;
    this.split = DisplayFormatter.formatTime(split, 1);
    this.rate = DisplayFormatter.formatNumber(rate, 1);

    if (pieceSpec.goal.type === WorkoutGoals.distance) {
      this.goal = this.distance;
      this.score = this.time;
    } else {
      this.goal = this.time;
      this.score = this.distance;
    }
  }
}