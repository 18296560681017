import { interpolateRgb } from "d3-interpolate";

export default class ColorScale {
  public static NegativeColor = "rgb(248, 105, 107)";
  public static NeutralColor = "rgb(255, 235, 132)";
  public static PositiveColor = "rgb(99, 190, 123)";

  public static createColor(value: number, positiveValue: number, neutralValue: number, negativeValue: number) {
    const higherIsPositive = positiveValue > neutralValue;
    if (higherIsPositive) {
      if (value >= positiveValue) {
        return ColorScale.PositiveColor;
      }

      if (value <= negativeValue) {
        return ColorScale.NegativeColor;
      }

      if (value >= neutralValue) {
        return ColorScale._createColor(ColorScale.PositiveColor, positiveValue, neutralValue, value);
      }

      return ColorScale._createColor(ColorScale.NegativeColor, negativeValue, neutralValue, value);
    }

    if (value <= positiveValue) {
      return ColorScale.PositiveColor;
    }

    if (value >= negativeValue) {
      return ColorScale.NegativeColor;
    }

    if (value <= neutralValue) {
      return ColorScale._createColor(ColorScale.PositiveColor, positiveValue, neutralValue, value);
    }

    return ColorScale._createColor(ColorScale.NegativeColor, negativeValue, neutralValue, value);
  }

  private static _createColor(comparisonColor: string, comparisonBoundary: number, neutralBoundary: number, value: number): string {
    const interpolator = interpolateRgb(comparisonColor, ColorScale.NeutralColor);
    const percentage = (value - comparisonBoundary) / (neutralBoundary - comparisonBoundary);

    return interpolator(percentage);
  }
}