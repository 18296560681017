import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AthletePieceGraph } from '../../../workouts/AthletePieceGraph';

export const AthletePieceGraphPage = (props: RouteComponentProps<any>) => {
  const { params } = props.match;

  return (
    <AthletePieceGraph
      workoutId={params.workoutId}
      pieceKey={params.pieceKey} />
  );
}