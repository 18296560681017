import React, { useEffect, useState } from "react";
import { zoom, zoomIdentity } from "d3-zoom";
import { select, event as d3Event } from "d3-selection";
import { scaleLinear, scaleTime } from "d3-scale";
import { axisBottom, axisLeft } from "d3-axis";
import { line } from "d3-shape";
import GetPersonalPieceDataQuery from "../../services/rowhero/queries/GetPersonalPieceDataQuery";
import { useAppServices } from "../../providers/AppServiceProvider";
import { WorkoutPieceSpecification, WorkoutGoals, WorkoutPieceStatisticsResponse } from "../../services/rowhero/RowHeroServiceApi";
import { DisplayFormatter } from "../../common";
import { Loading } from "../common/loading/Loading";
import GetPersonalPieceStrokeDataQuery from "../../services/rowhero/queries/GetPersonalPieceStrokeDataQuery";
import { ResponsiveSvg } from "../common/dataviz/ResponsiveSvg"
import "./styles.css";
import { extent } from "d3-array";
import { quantileSorted, minSorted, maxSorted } from "simple-statistics";

export interface AthletePieceGraphProps {
  workoutId: string;
  pieceKey: string;
}

class AthletePieceGraphViewModel {
  statistics?: WorkoutPieceStatisticsResponse;
  spec?: WorkoutPieceSpecification;

  get isDistance(): boolean {
    return this.spec && this.spec.goal.type === WorkoutGoals.distance || false;
  }
}

export const AthletePieceGraph = (props: AthletePieceGraphProps) => {
  const { rowHeroClient, strokeDataHttpClient } = useAppServices();
  const [viewModel, setViewModel] = useState<AthletePieceGraphViewModel>(new AthletePieceGraphViewModel());
  const [strokeData, setStrokeData] = useState<[number, number][]>();

  useEffect(() => {
    if (!rowHeroClient || !strokeDataHttpClient) {
      return;
    }

    new GetPersonalPieceDataQuery(
      props.workoutId,
      props.pieceKey
    ).queryAsync(rowHeroClient)
      .then(data => {
        viewModel.statistics = data.statistics;
        viewModel.spec = data.pieceSpec;

        return new GetPersonalPieceStrokeDataQuery(data.uri)
          .queryAsync(strokeDataHttpClient)
          .then(strokes => {
            const isDistance = data.pieceSpec.goal.type === WorkoutGoals.distance;
            setStrokeData(strokes.map(s => [isDistance ? s.cdr : s.cet / 1000.0, 500.0 / s.s]));
          })
          .catch(reason => setStrokeData([]))
      })
  }, [rowHeroClient, strokeDataHttpClient, props.workoutId, props.pieceKey]);

  const render = (node: SVGSVGElement, availableWidth: number, availableHeight: number) => {
    if (!strokeData || !strokeData.length || !viewModel.spec || !viewModel.statistics) {
      return;
    }

    const margin = {
      top: 20,
      bottom: 30,
      left: 50,
      right: 30
    }

    const canvasWidth = availableWidth - margin.left - margin.right;
    const canvasHeight = availableHeight - margin.top - margin.bottom;

    const xExtent = extent(strokeData.map(d => d[0]));

    const splits = strokeData.map(d => d[1]).sort();

    // Combining quantileSorted into one call breaks (bug in their code).
    const firstDecile = quantileSorted(splits, 0.1);
    const ninthDecile = quantileSorted(splits, 0.9);
    const min = minSorted(splits);
    const max = maxSorted(splits);
    const outlierAddend = (ninthDecile - firstDecile) * 1.5;
    let yExtent = [firstDecile - outlierAddend, ninthDecile + outlierAddend];
    const range = (max - min) * .1;
    let fullPanRange = [min - range, max + range];

    const x = scaleLinear().domain([xExtent[0]!, xExtent[1]!]).range([0, canvasWidth]);
    const y = scaleLinear().domain([yExtent[1]!, yExtent[0]!]).range([0, canvasHeight]);

    const lineFn = line()
      .x(d => x(d[0]))
      .y(d => y(d[1]));

    const applyMultiplier = !viewModel.isDistance && xExtent[1]! > viewModel.spec.goal.value * .9;

    const xAxis = axisBottom<number>(x)
      .tickFormat(value => {
        return viewModel.isDistance
          ? DisplayFormatter.formatNumber(value, 0)
          : DisplayFormatter.formatTime(value * (applyMultiplier ? 1 : 1000), 0)
      });

    const yAxis = axisLeft(y)
      .ticks(5)
      .tickFormat(value => DisplayFormatter.formatTime(value.valueOf(), 1));

    const averageData: [number, number][] = [
      [0, viewModel.statistics.averageSplit],
      [xExtent[1]!, viewModel.statistics.averageSplit]
    ];

    // Rendering
    const mainGroup = select(node)
      .select("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`)
      ;

    mainGroup 
      .selectAll("g, defs, rect")
      .remove();

    mainGroup
      .append('defs')
      .append('clipPath')
      .attr('id', 'plot-area-clip-path')
      .append('rect')
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", canvasWidth)
      .attr("height", canvasHeight);

    mainGroup.append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0, ${canvasHeight})`)
      .attr("pointer-events", "none")
      .call(xAxis);

    mainGroup.append("g")
      .attr("class", "y-axis")
      .attr("pointer-events", "none")
      .call(yAxis);

    const pathGroup = mainGroup
      .append("g")
      .attr("pointer-events", "none")
      .attr('clip-path', 'url(#plot-area-clip-path)')
      ;

    pathGroup.append("path")
      .attr("class", "line")
      .attr("shape-rendering", "geometricPrecision")
      .attr("d", lineFn(strokeData)!)
      .attr("stroke-width", "2px");
      // .attr("transform", d => `translate(${zoomIdentity.apply(d as any)})`);
    
    pathGroup.append("path")
      .attr("class", "average")
      .attr("d", lineFn(averageData)!)
      .attr("stroke-width", "2px");


    // Zoom behavior
    const zoomFn = zoom<SVGRectElement, unknown>()
      .scaleExtent([1, 5])
      .translateExtent([[0, y(fullPanRange[1])], [availableWidth, y(fullPanRange[0])]])
      .on('zoom', () => {
        const transform = d3Event.transform;

        const newScaleX = transform.rescaleX(x);
        const newScaleY = transform.rescaleY(y);

        mainGroup.select(".x-axis")
          .call(xAxis.scale(newScaleX) as any);

        mainGroup.select(".y-axis")
          .call(yAxis.scale(newScaleY) as any);

        pathGroup.selectAll(".line, .average")
          .attr("transform", transform)
          .attr("stroke-width", `${2.0 / transform.k}px`);
      });

    mainGroup
      .append('rect')
      .style('stroke', 'none')
      .style('fill', '#FFF')
      .style('fill-opacity', 0)
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", canvasWidth)
      .attr("height", canvasHeight)
      .attr("pointer-events", "all")
      .call(zoomFn);
  };

  if (!strokeData) {
    return <Loading />;
  }

  if (!strokeData.length) {
    return (
      <span>We're sorry. Graph data could not be found for this piece. :(</span>
    )
  }

  return (
    <div>
      <ResponsiveSvg
        contentContainerClassName="piece-graph"
        render={render} />
      
      <div className="graph-no-mouse-instructions">
      </div>
      <div className="graph-mouse-instructions">
        <p>Double click to zoom in.</p>
        <p>Hold Shift + Double Click to zoom out.</p>
        <p>Click and drag to move the graph around while zoomed in.</p>
      </div>
    </div>
  );
};