import moment from 'moment';

export default class WorkoutHistoryMonthBoundary {
  constructor(public year: number, public month: number) {
    
  }

  toString = (): string => {
    let monthString = this.month < 10 ? `0${this.month}` : this.month.toString();

    return `${this.year}${monthString}`;
  }

  addMonths = (months: number): WorkoutHistoryMonthBoundary => {
    const toMoment = this.moment();
    const before = toMoment.add(months, "months");

    return new WorkoutHistoryMonthBoundary(before.year(), before.month() + 1);
  }

  moment = (): moment.Moment => {
    return moment(`${this.year}-${this.month}`, "YYYY-M");
  }
}