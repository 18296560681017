import { WorkoutHistoryMonthBoundary } from ".";
import moment from 'moment';

export default class WorkoutHistoryParams {
  constructor(public from: WorkoutHistoryMonthBoundary, public to: WorkoutHistoryMonthBoundary) {
    
  }

  static fromPage = (page: number) => {
    if (page < 0) {
      throw new Error('Page must be greater than or equal to 0.');
    }

    const now = moment();
    const monthsAgo = page * -6;

    const to = now.add(monthsAgo, "months");
    const from = to.clone().add(-5, "months");

    return new WorkoutHistoryParams(
      new WorkoutHistoryMonthBoundary(from.year(), from.month() + 1),
      new WorkoutHistoryMonthBoundary(to.year(), to.month() + 1));
  }

  static create = (from?: WorkoutHistoryMonthBoundary, to?: WorkoutHistoryMonthBoundary): WorkoutHistoryParams => {
    if (!from) {
      if (!to) {
        const current = moment();
        to = new WorkoutHistoryMonthBoundary(current.year(), current.month() + 1);
      }

      from = to.addMonths(-6);
    }
    else if (!to) {
      const current = moment();
      to = new WorkoutHistoryMonthBoundary(current.year(), current.month() + 1);
      if (from.year > to.year || from.year === to.year && from.month > to.month) {
        throw new Error('The from date cannot be in the future.');
      }
    }

    return new WorkoutHistoryParams(from, to);
  }
}