import { useState, useEffect } from "react";
import React from "react";
import { select } from "d3-selection";

export interface ResponsiveSvgProps {
  minHeight?: number
  contentContainerStyle?: React.CSSProperties
  contentContainerClassName?: string

  render: (element: SVGSVGElement, availableWidth: number, availableHeight: number) => void;
}

export const ResponsiveSvg = (props: ResponsiveSvgProps) => {
  let ref: SVGSVGElement | null = null;

  useEffect(() => {
    const handler = () => loadGraph(ref!, true);
    window.addEventListener("resize", handler);

    return () => {
      window.removeEventListener("resize", handler);
    }
  });

  const loadGraph = (node: SVGSVGElement, fromResize?: boolean) => {
    if (!node) {
      return;
    }

    ref = node;

    const parentElement = node.parentElement;
    if (!parentElement) {
      return;
    }

    const svg = select(node);
    if (fromResize) {
      svg
        .attr("width", 0)
        .attr("height", 0);
      loadGraph(node);
      return;
    }

    const availableWidth = parentElement.clientWidth; 
    const availableHeight = parentElement.scrollHeight;

    // console.log(`${availableWidth} x ${availableHeight}`)

    if (!availableHeight || !availableWidth) {
      return;
    }

    svg.attr("width", availableWidth).attr("height", availableHeight);

    props.render(node, availableWidth, availableHeight);
  };

  const style = {
    display: "flex",
    flex: 1,
    alignSelf: "stretch",
    minHeight: props.minHeight
  };

  return (
    <div
      className={props.contentContainerClassName}
      style={props.contentContainerStyle || style}>
      <svg ref={loadGraph} width="0" height="0">
        <g></g>
      </svg>
    </div>
  );
};