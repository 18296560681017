// from https://auth0.com/docs/quickstart/spa/react?download=true
import React, { useState, useEffect, useContext } from "react";
import { useAuth0 } from "./Auth0Provider";
import { RowHeroHttpClient, FetchHttpClient, DefaultRetryHttpClient } from "../services/http";
import HttpClient from "../services/http/HttpClient";

type AppServiceType = {
  rowHeroClient?: HttpClient
  strokeDataHttpClient?: HttpClient
};

const defaultContext: AppServiceType = {
};

export const AppServiceContext = React.createContext(defaultContext);
export const useAppServices = () => useContext(AppServiceContext);
export const AppServiceProvider = ({
  children
} : {
  children: any
}) => {
  const [rowHeroClient, setRowHeroClient] = useState();
  const [strokeDataHttpClient, setStrokeDataHttpClient] = useState();

  const { authService } = useAuth0();

  useEffect(() => {
    if (!authService) {
      return;
    }

    authService.getTokenSilently().then(token => {
      let client = new RowHeroHttpClient(token, new FetchHttpClient(), authService);
      setRowHeroClient(client);

      setStrokeDataHttpClient(new DefaultRetryHttpClient());
    }).catch(reason => {
      console.log('token retrieval error: ' + JSON.stringify(reason));
    });
  }, [authService]);

  const value = {
    rowHeroClient,
    strokeDataHttpClient
  };

  return (
    <AppServiceContext.Provider
      value={value}>
      {children}
    </AppServiceContext.Provider>
  );
};