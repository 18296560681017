import React from "react";
import { WorkoutMetricResponse } from "../../../services/rowhero/RowHeroServiceApi";
import { DisplayFormatter } from "../../../common";

export interface RankProps {
  className?: string;
  style?: React.CSSProperties
  value: WorkoutMetricResponse;
}

export const Rank = (props: RankProps) => {
  const metric = props.value;

  if (metric.total <= 1) {
    return null;
  }

  const position = DisplayFormatter.formatOrdinal(metric.rank);
  const total = metric.total.toString();

  return (
    <span className={props.className} style={props.style}>
      {position} of {total}
    </span>
  );
}