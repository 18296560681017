import { sprintf } from "sprintf-js";
import { WorkoutPieceSpecification, WorkoutGoals } from "../services/rowhero/RowHeroServiceApi";

export default class DisplayFormatter {
  public static formatPercentage(value: number, decimalPlaces: number) {
    let text = this.formatNumber(value * 100, decimalPlaces);

    return text + '%';
  }

  public static formatOffStrokeImpact(spec: WorkoutPieceSpecification, value?: number) {
    if (!value) {
      return "--";
    }

    const isTime = spec.goal.type === WorkoutGoals.time;
    if (isTime) {
      return DisplayFormatter.formatMeters(Math.ceil(value), 0);
    }

    return DisplayFormatter.formatTime(Math.ceil(value * 10) / 10.0, 1);
  }

  public static formatOffStrokeScore(value: number) {
    return DisplayFormatter.formatNumber(Math.floor(value), 0);
  }

  public static formatNumber(value: number, decimalPlaces: number, removeTrailingZeroes?: boolean) {
    let multiplier = Math.pow(10, decimalPlaces);
    let rounded = Math.round(value * multiplier) / multiplier;

    let format = "%." + decimalPlaces + "f";
    let text = sprintf(format, rounded);

    if (removeTrailingZeroes) {
      return text.replace(/.?0+$/, "");
    }

    return text;
  }

  public static formatMeters(meters: number, decimalPlaces: number, removeTrailingZeroes?: boolean) {
    if (meters % 1000 === 0) {
      return (meters / 1000) + 'k';
    }
    
    return DisplayFormatter.formatNumber(meters, decimalPlaces, removeTrailingZeroes) + "m";
  }

  public static formatNumberChange(value: number, decimalPlaces: number, removeTrailingZeroes?: boolean) {
    let str = DisplayFormatter.formatNumber(value, decimalPlaces, removeTrailingZeroes);

    if (value > 0) {
      return `+${str}`;
    }

    return str;
  }

  public static formatTimeChange(seconds: number, decimalPlaces: number, removeTrailingZeroes?: boolean) {
    let str = DisplayFormatter.formatTime(seconds, decimalPlaces, removeTrailingZeroes);

    if (seconds > 0) {
      return `+${str}`;
    }

    return str;
  }

  public static formatOrdinal(position: number) {
    let order = 'th';

    switch (position) {
      case 1:
        order = 'st';
        break;
      case 2:
        order = 'nd';
        break;
      case 3:
        order = 'rd';
        break;
    }

    if (position > 20) {
      if ((position - 1) % 10 === 0) {
        order = 'st';
      } else if ((position - 2) % 10 === 0) {
        order = 'nd';
      } else if ((position - 3) % 10 === 0) {
        order = 'rd';
      }
    }

    return position + order;
  }

  public static formatTime(seconds: number, decimalPlaces: number, removeTrailingZeroes?: boolean) {
    let multiplier = Math.pow(10, decimalPlaces);
    let abs = Math.abs(Math.round(seconds * multiplier) / multiplier);

    let secondFormat = "%0" + (decimalPlaces === 0 ? 2 : decimalPlaces + 3) + "." + decimalPlaces + "f";
    let final = "";

    if (abs > 3600) {
      final = sprintf("%d:%02d:" + secondFormat, abs / 3600, (abs % 3600) / 60, abs % 60);
    } else {
      final = sprintf("%d:" + secondFormat, (abs % 3600) / 60, abs % 60);
    }

    let text = seconds < 0 ? "-" + final : final;

    if (removeTrailingZeroes) {
      return text.replace(/\.?0+$/, "");
    }

    return text;
  }

  public static formatTimeSpec(seconds: number) {
    if (seconds < 60) {
      return `${seconds}"`;
    }

    if (seconds % 60 === 0 && seconds < 3600) {
      return `${seconds / 60}'`;
    }

    return DisplayFormatter.formatTime(seconds, 0);
  }
}