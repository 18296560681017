import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client";

export default class AuthenticationService {
  isAuthenticated: boolean = false;
  user: any = {};
  loading: boolean = true;
  popupOpen: boolean = false;

  private accessTokenFromUserAgent?: string;

  constructor(private auth0Client: Auth0Client) {
  }

  getIdTokenClaims = (options?: getIdTokenClaimsOptions) => this.auth0Client.getIdTokenClaims(options);

  getTokenSilently = async (options?: GetTokenSilentlyOptions) =>  {
    if (this.accessTokenFromUserAgent) {
      return this.accessTokenFromUserAgent;
    }

    return await this.auth0Client.getTokenSilently(options);
  };

  getTokenWithPopup = (options?: GetTokenWithPopupOptions) => this.auth0Client.getTokenWithPopup(options);

  getUser = async () => {
    if (this.accessTokenFromUserAgent) {
      return {};
    }

    return await this.auth0Client.getUser();
  }

  handleRedirectCallback = async () => {
    this.loading = true;
    await this.auth0Client.handleRedirectCallback();
    const user = await this.auth0Client.getUser();
    this.loading = false;
    this.isAuthenticated = true;
    this.user = user;
  };

  loginWithPopup = async (params: PopupLoginOptions = {}) => {
    this.popupOpen = true;
    try {
      await this.auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      this.popupOpen = false;
    }
    const user = await this.auth0Client.getUser();
    this.user = user;
    this.isAuthenticated = true;
  };
  
  loginWithRedirect = (options?: RedirectLoginOptions) => this.auth0Client.loginWithRedirect(options);
  logout = (options?: LogoutOptions) => this.auth0Client.logout(options);

  updateAuthenticatedState = async () => {
    if (this.accessTokenFromUserAgent) {
      this.isAuthenticated = true;
      return;
    }

    this.isAuthenticated = await this.auth0Client.isAuthenticated();

    if (this.isAuthenticated) {
      this.user = await this.auth0Client.getUser();
    }
  }

  setToken = (accessToken: string) => {
    this.accessTokenFromUserAgent = accessToken;
  }
}