import React, { useEffect, useState } from 'react';
import { useAppServices } from '../../providers/AppServiceProvider';
import { GetPersonalWorkoutResultsQuery } from '../../services/rowhero/queries';
import { WorkoutRankingListResponse } from '../../services/rowhero/RowHeroServiceApi';
import { Loading } from '../common/loading/Loading';
import { AthleteWorkoutPieceCard } from './AthleteWorkoutPieceCard';

export interface AthleteWorkoutPieceListProps {
  workoutId: string;
}

export const AthleteWorkoutPieceList = (props: AthleteWorkoutPieceListProps) => {
  const { rowHeroClient } = useAppServices();

  const [workout, setWorkout] = useState<WorkoutRankingListResponse>();
  
  useEffect(() => {
    if (!rowHeroClient) {
      return;
    }

    new GetPersonalWorkoutResultsQuery(props.workoutId)
      .queryAsync(rowHeroClient)
      .then(response => {
        setWorkout(response);
      });
  }, [rowHeroClient, props.workoutId]);

  if (!workout) {
    return <Loading />;
  }

  const cards = workout.pieces.map((p, i) =>
    <AthleteWorkoutPieceCard
      key={p.pieceNumber}
      personalWorkoutId={props.workoutId}
      piece={p}
      pieceIndex={i}
      pieceCount={workout.pieces.length} />);

  return (
    <div>
      {cards}
    </div>
  );
};