import React from 'react';
import { AthleteWorkoutList } from '../../../workouts/AthleteWorkoutList';
import { RouteComponentProps } from 'react-router-dom';

export const AthleteTeamWorkoutListPage = (props: RouteComponentProps<any>) => {
  const { params } = props.match;


  return (
    <AthleteWorkoutList
      teamId={params.teamId} />
  );
}