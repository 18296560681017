import HttpClient from '../../http/HttpClient.js';
import { ProfileDataResponse } from "../RowHeroServiceApi";
import config from '../../../config/config.json';
import { mapJson } from "../../http/operators";

export default class ProfileQuery {
  queryAsync(httpClient: HttpClient): Promise<ProfileDataResponse> {
    const uri = `${config.rowhero.baseUri}/v1/me/profile`;

    return httpClient.getAsync(uri)
      .pipe(
        mapJson<ProfileDataResponse>()
      )
      .toPromise();
  }
}