import React, { useEffect, useState } from "react";
import { scaleLinear } from "d3-scale";
import { axisBottom, axisLeft } from "d3-axis";
import { extent } from "d3-array";
import { select } from "d3-selection";
import { line } from "d3-shape";
import { WorkoutPieceSpecification, WorkoutGoals, WorkoutPieceStatisticsResponse } from "../../services/rowhero/RowHeroServiceApi";
import { DisplayFormatter } from "../../common";
import { Loading } from "../common/loading/Loading";
import { ResponsiveSvg } from "../common/dataviz/ResponsiveSvg";

export interface AthletePieceSplitSparklineProps {
  minHeight?: number
  pieceSpec: WorkoutPieceSpecification
  statistics?: WorkoutPieceStatisticsResponse
}

export const AthletePieceSplitSparkline = (props: AthletePieceSplitSparklineProps) => {
  const isDistance = props.pieceSpec.goal.type === WorkoutGoals.distance;

  const render = (element: SVGSVGElement, availableWidth: number, availableHeight: number) => {
    if (!props.statistics || !props.statistics.splits) {
      return;
    }

    const splits = props.statistics.splits;
    const data: [number, number][] = splits.map((s, index) => [
      isDistance ? s.distance : s.time,
      index === 0 ? s.time / s.distance * 500.0 : (s.time - splits[index - 1].time) / (s.distance - splits[index - 1].distance) * 500.0
    ])

    const smallDisplay = availableWidth < 400;
    const verySmallDisplay = availableWidth < 125;

    const margin = {
      top: 20,
      right: smallDisplay ? 0 : 20,
      bottom: smallDisplay ? 5 : 20,
      left: verySmallDisplay ? 0 : 40
    };

    const canvasWidth = availableWidth - margin.left - margin.right;
    const canvasHeight = availableHeight - margin.top - margin.bottom;

    const xExtent = extent(data.map(d => d[0]));
    let yExtent = extent(data.map(d => d[1]));
    
    // yExtent should have a 10% buffer on each side.
    const yRange = yExtent[1]! - yExtent[0]!;
    
    yExtent = [yExtent[0]! - (0.1 * yRange), yExtent[1]! + (0.1 * yRange)];

    const x = scaleLinear().domain([xExtent[0]!, xExtent[1]!]).range([0, canvasWidth]);
    const y = scaleLinear().domain([yExtent[1]!, yExtent[0]!]).range([0, canvasHeight]);

    const lineFn = line()
      .x(d => x(d[0]))
      .y(d => y(d[1]));

    const svg = select(element);
    const group = svg
      .select("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

      group 
      .selectAll("g")
      .remove();

    const applyMultiplier = !isDistance && xExtent[1]! > props.pieceSpec.goal.value * .9;

    if (!verySmallDisplay) {
      const yAxis = axisLeft(y)
        .ticks(3)
        .tickFormat(value => DisplayFormatter.formatTime(value.valueOf(), 1));
      group.append("g")
        .call(yAxis)
        // Remove the axis line on small displays
        .call(g => smallDisplay && g.select('.domain').remove())
    }

    if (!smallDisplay) {
      const xAxis = axisBottom<number>(x)
        .tickFormat(value => {
          return isDistance
            ? DisplayFormatter.formatNumber(value, 0)
            : DisplayFormatter.formatTime(value * (applyMultiplier ? 1 : 1000), 0)
        });
        group.append("g")
        .attr("transform", `translate(0, ${canvasHeight})`)
        .call(xAxis);
    }

    const g = group.append("g");

    g.selectAll("path.line")
      .data(data)
      .enter()
        .append("path")
        .attr("class", "line")
        .attr("d", lineFn(data)!);
  };

  if (!props.statistics) {
    return <Loading />;
  }

  if (!props.statistics.splits || props.statistics.splits.length < 2) {
    return null;
  }

  return (
    <ResponsiveSvg
      minHeight={props.minHeight}
      render={render} />
  );
};